import React, { useState, useEffect } from "react";
// import { Sidebar } from '../sidebar/Sidebar';
import { FcPlus } from "react-icons/fc";
// import { Button } from "@mui/material";

import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBInput,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";
import "../../assets/css/style.css";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Select from "react-select";
import DeleteIcon from "@mui/icons-material/Delete";

// import { DialogTitle, DialogContent, DialogActions, Dialog} from '@mui/material';
// import { DialogTitle, DialogContent, DialogActions, Dialog} from '@mui/material';1223
export const NewLeads = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [centredModal, setCentredModal] = useState(false);
  const [centredModal1, setCentredModal1] = useState(false);
  const [org_name, setOrg] = useState();
  const [contact_person, setPerson] = useState();
  const [location, setLocation] = useState();
  const [mobile, setMobile] = useState();
  const [email, setEmail] = useState();
  const [service, setService] = useState();
  //  const [service_Sub_Types.set]=useState()
  const [status, setStatus] = useState();
  const [Remark, setRemark] = useState("");
  // const [trans_lead,setTranLead]=useState()
  const [updateId, setUpdateId] = useState(null);

  const [user, setUser] = useState(null);

  function getUser() {
    let userInfo = JSON.parse(localStorage.getItem("user-info"));
    let user1 = userInfo.user_data.id;

    // console.log(user1)
    fetch(`https://humhai.in/Team-leader/Lead/${user1}/`)
      .then((res) => res.json())
      .then((result) => {
        setUser(result);
      });
  }

  useEffect(() => {
    getUser();
  }, []);
  const [selectedOptions, setSelectedOptions] = useState();
  // const [selectedOptions1, selectedServiceValues] = useState();

  function selectUser(id, i) {
    // console.log("fhgs",user)
    // console.log("select data", id)
    openModal1();
    setOrg(user[i]?.org_name);
    setPerson(user[i]?.contact_person);

    setLocation(user[i]?.location);
    setMobile(user[i]?.mobile);
    setEmail(user[i]?.email);
    setService(user[i]?.service);
    setStatus(user[i]?.status);
    // setRemark(user[i]?.Remark)
    // setTranLead(user?.)
    setUpdateId(id);
  }

  const getCookie = (name) => {
    var cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  };

  const newLeads = async (e) => {
    e.preventDefault();
    const selectedServiceValues = selectedOptions.map((option) => option.value);
    const sevicesList = selectedServiceValues.toString();
    // console.log(selectedServiceValues, "sdfjhskh")
    // setIsLoading(true);
    let userInfo = JSON.parse(localStorage.getItem("user-info"));
    let user = userInfo.user_data.id;
    // console.log( "username");
    var csrftoken = getCookie("csrftoken");
    let result = await fetch("https://humhai.in/Team-leader/Lead/", {
      method: "POST",
      body: JSON.stringify({
        org_name: org_name,
        contact_person: contact_person,
        location: location,
        mobile: mobile,
        email: email,
        service: sevicesList,
        Remark: Remark,
        Added_by: user,
      }),
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        "X-CSRFToken": csrftoken,
      },
    })
      .then((res) => {
        // console.log(res, "response");
        return res;
      })
      .catch((error) => {
        console.log(error, "error");
      });

    // console.log("after response");
    result = await result.json();
    // console.log(result, "result");
    // console.log(result.status, "true");
    if (result.status === 0) {
      closeModal();
      setOrg("");
      setPerson("");
      setLocation("");
      setMobile("");
      setEmail("");
      setService("");
      setStatus("");
      setRemark("");
      setSelectedOptions("");
      getUser();
    }
    if (result.status === 404) {
      // setIncorrect1(true);
    }
    if (result.status === 401) {
    }

    console.log(result.msg);
  };

  const newLeadsUpdate = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    const selectedServiceValues = selectedOptions.map((option) => option.value);
    const sevicesList = selectedServiceValues.toString();
    let userInfo = JSON.parse(localStorage.getItem("user-info"));
    let user = userInfo.user_data.id;
    console.log(updateId, "username");
    var csrftoken = getCookie("csrftoken");
    let result = await fetch(
      `https://humhai.in/Team-leader/Lead/${updateId}/`,
      {
        method: "PUT",
        body: JSON.stringify({
          org_name: org_name,
          contact_person: contact_person,
          location: location,
          mobile: mobile,
          email: email,
          service: sevicesList,
          status: status,
          // Remark: Remark,
          Added_by: user,
        }),

        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          "X-CSRFToken": csrftoken,
        },
      }
    )
      .then((res) => {
        console.log(res, "response");
        return res;
      })
      .catch((error) => {
        console.log(error, "error");
        alert("Internal server error");
      });

    console.log("after response");
    result = await result.json();
    console.log(result, "result");
    console.log(result.status, "true");
    if (result.status === 0) {
      closeModal1();
      setOrg("");
      setPerson("");
      setLocation("");
      setMobile("");
      setEmail("");
      setService("");
      setStatus("");
      setRemark("");
      getUser();
      // setSuccess(true);
      // setTimeout(() => {
      //   navigate("/sidebar");
      //   setSuccess(false);
      // }, 3000);
      // setIsLoading(false);
    }
    if (result.status === 404) {
      // setIncorrect1(true);
    }
    if (result.status === 500) {
      // setIncorrect(true);
    }

    console.log(result.msg);
  };
  function newLeadsDelete(id) {
    var retVal = window.confirm("Are you sure ?");
    console.log(id,"id")
    if (retVal === true) {
        var csrftoken = getCookie("csrftoken");
        fetch(`https://humhai.in/Team-leader/Lead/${id}/`, {
            method: 'DELETE',
            headers: {
                "Content-Type": 'application/json',
                "Accept": 'application/json',
                "X-CSRFToken": csrftoken,
            }
        }).then((result) => {
            result.json().then((res) => {
              
              getUser()
              alert(res.status,"res")
            })
        })
        
        return true;
    }
    else {
        return false;
    }
}

  // function newLeadsDelete(id,e) {
  //   e.preventDefault()
  //   var retVal = window.confirm("Are you sure ?");
  //   if (retVal === true) {
  //     var csrftoken = getCookie("csrftoken");
  //     console.log(id,"id")
  //     fetch(`https://humhai.in/Team-leader/Lead/${id}/`, {
  //       method: "DELETE",
  //       // body: JSON.stringify(
  //       //   {
  //       //     id:id
  //       //   }),
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //         // "user":{user1?.user_data?.id}

  //         // "Aceess-Control-Allow-Origin": "*"
  //         "X-CSRFToken": csrftoken,
  //       },
  //     }).then((result) => {
  //       result.json().then((res) => {
  //         console.log("hii");
  //         // handleClose1()
  //         getUser();
  //       });
  //     });
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  const openModal = () => setCentredModal(true);
  const closeModal = () => setCentredModal(false);

  const openModal1 = () => setCentredModal1(true);
  const closeModal1 = () => {
    setCentredModal1(false);
    setOrg("");
    setPerson("");
    setLocation("");
    setMobile("");
    setEmail("");
    setService("");
    setStatus("");
    // setRemark("");
  };

  const optionList = [
    {
      value: "Background Check",
      label: "Background Check",
    },
    { value: "Recruitment", label: "Recruitment" },
    {
      value: "Software/Website Development",
      label: "Software/Website Development",
    },
    { value: "Project Management", label: "Project Management" },
    { value: "Start-up Studio", label: "Start-up Studio" },
    { value: "Graphic Design", label: "Graphic Design" },
  ];

  function handleSelect(data) {
    setSelectedOptions(data);
  }

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            marginTop: !matches ? "15%" : "25%",
            marginLeft: !matches ? "80%" : "55%",
          }}
        >
          <MDBBtn color="secondary" onClick={() => openModal()}>
            <span style={{ display: "flex", flexDirection: "row" }}>
              Add Leads
              <FcPlus size={22} className="ms-2" />
            </span>
          </MDBBtn>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              padding: 20,
              margin: 10,
              background: "#FFF",
              width: "90%",
              borderRadius: 10,
              overflowX: "scroll",
              // zIndex:1
            }}
          >
            <MDBTable>
              <MDBTableHead>
                <tr style={{ fontSize: "14px" }}>
                  <th>Lead_id</th>
                  <th>Organization_Name</th>
                  <th>Location</th>
                  <th>Contact_Person</th>
                  <th>Mobile</th>
                  <th>Email_id</th>
                  <th>Service</th>
                  {/* <th>service_Sub_Types</th>
                  <th>status</th> */}
                  <th>Remark</th>
                  <th>Created_By</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {user?.map((userObj, index) => (
                  <tr>
                    <td>{userObj.id}</td>
                    <td>{userObj.org_name}</td>
                    <td>{userObj.contact_person}</td>
                    <td>{userObj.location}</td>
                    <td>{userObj.mobile}</td>
                    <td>{userObj.email}</td>
                    <td>{userObj.service}</td>
              
                    {/* <td>{userObj.status}</td> */}
                    <td>{userObj.Remark}</td>
                    <td>{userObj.create_by}</td> 
                    {/* <td>{userObj.Added_by}</td>
                    <td>{userObj.created}</td> */}
                    <td onClick={() => selectUser(userObj.id, index)}>
                      <MDBBtn
                        type="submit"
                        style={{
                          backgroundImage:
                            "linear-gradient(to right, red , yellow)",
                        }}
                      >
                        EDIT
                      </MDBBtn>
                    </td>
                    <td onClick={()=>newLeadsDelete(userObj.id)}>
                      <DeleteIcon style={{ cursor: "pointer" }} />
                    </td>
                  </tr>
                ))}
              </MDBTableBody>
            </MDBTable>
          </div>
        </div>
      </div>

      {/* 1 form  */}
      <MDBModal tabIndex="-1" show={centredModal} setShow={setCentredModal}>
        <MDBModalDialog centered style={{ marginTop: 130 }}>
          <MDBModalContent className="addleads ">
            <MDBModalHeader>
              <MDBModalTitle>Add Leads</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => {
                  closeModal();
                }}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <form onSubmit={newLeads}>
                {/* <h1>form</h1> */}
                <MDBRow className="mb-3">
                  <MDBCol md={6} className="mb-3">
                    <MDBInput
                      id="form3Example1"
                      label="Organization Name"
                      name="org_name"
                      onChange={(e) => setOrg(e.target.value)}
                      value={org_name}
                    />
                  </MDBCol>
                  <MDBCol md={6}>
                    <MDBInput
                      id="form3Example2"
                      label="Location"
                      name="Location"
                      onChange={(e) => setLocation(e.target.value)}
                      value={location}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow md={6}>
                  <MDBCol className="mb-3">
                    <MDBInput
                      id="form3Example1"
                      label="Contact Person"
                      name="Contact Person"
                      onChange={(e) => setPerson(e.target.value)}
                      value={contact_person}
                    />
                  </MDBCol>
                  <MDBCol md={6}>
                    <MDBInput
                      id="form3Example2"
                      label="Mobile No."
                      name="Mobile No."
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mb-4">
                  <MDBCol md={6} className="mb-3 mt-3">
                    <MDBInput
                      id="form3Example1"
                      label="Email Id"
                      name="Email Id"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </MDBCol>
                  <MDBCol className="mt-3">
                    <MDBInput
                      id="form3Example1"
                      label="Remark"
                      name="Remark"
                      onChange={(e) => setRemark(e.target.value)}
                      value={Remark}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                  <div className="dropdown-container">
                    <Select
                      options={optionList}
                      placeholder="Service"
                      value={selectedOptions}
                      onChange={handleSelect}
                      isSearchable={true}
                      isMulti
                    />
                  </div>
                </MDBRow>

                {/* <MDBInput className='mb-4' type='email' id='form3Example3' label='status' /> */}
                {/* <MDBInput className='mb-4' type='password' id='form3Example4' label='Password' /> */}

                <MDBBtn type="submit" className="mb-4" block>
                  Submit
                </MDBBtn>
              </form>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      {/* 2 form  update*/}
      <MDBModal tabIndex="-1" show={centredModal1} setShow={setCentredModal1}>
        <MDBModalDialog centered style={{ marginTop: 130 }}>
          <MDBModalContent className="addleads">
            <MDBModalHeader>
              <MDBModalTitle>Update Leads</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => {
                  closeModal1();
                }}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <form onSubmit={newLeadsUpdate}>
                <MDBRow className="mb-4">
                  <MDBCol md={6} className="mb-3">
                    <MDBInput
                      id="form3Example1"
                      label="Organization Name"
                      name="org_name"
                      onChange={(e) => setOrg(e.target.value)}
                      value={org_name}
                    />
                  </MDBCol>
                  <MDBCol md={6}>
                    <MDBInput
                      id="form3Example2"
                      label="Location"
                      name="Location"
                      onChange={(e) => setLocation(e.target.value)}
                      value={location}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow md={6}>
                  <MDBCol className="mb-3">
                    <MDBInput
                      id="form3Example1"
                      label="Contact Person"
                      name="Contact Person"
                      onChange={(e) => setPerson(e.target.value)}
                      value={contact_person}
                    />
                  </MDBCol>
                  <MDBCol md={6}>
                    <MDBInput
                      id="form3Example2"
                      label="Mobile No."
                      name="Mobile No."
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                  <MDBCol md={12} className="mb-3 mt-3">
                    <MDBInput
                      id="form3Example1"
                      label="Email Id"
                      name="Email Id"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                  <div className="dropdown-container">
                    <Select
                      options={optionList}
                      placeholder="Service"

                      value={selectedOptions}
                      onChange={handleSelect}
                      isSearchable={true}
                      isMulti
                    />
                  </div>
                </MDBRow>

                {/* <MDBRow className="mb-4">
                  <MDBCol>
                    <select
                      value={status}
                      name="Status"
                      onChange={(e) => setStatus(e.target.value)}
                      className="form-control"
                      placeholder="status"
                      
                    >
                      <option value="">Yet To Connect</option>
                      <option value="interested">Interested</option>
                      <option value="not_interested">Not Interested</option>
                      <option value="call_you_later">Call You Later</option>
                      <option value="Invalid mobile number">
                        Invalid mobile Number
                      </option>
                      <option value="meeting schedule">Meeting Schedule</option>
                      <option value="Blacklisted/Blocked">
                        Blacklisted/Blocked
                      </option>
                      <option value="Transfer leads" id="transferlead">
                        Transfer Leads
                      </option>
                    </select>
                   
                  </MDBCol>
                  {status === "Transfer leads" && (
                    <MDBCol>
                      <select className="form-control" >
                        <option value="">Transfer Leads To</option>
                        <option value="deepika">Deepika</option>
                        <option value="anadita">Anandita</option>
                        <option value="pinky">Pinky</option>
                      </select>
                    </MDBCol>
                  )}
                </MDBRow> */}

                {/* <MDBInput className='mb-4' type='email' id='form3Example3' label='status' /> */}
                {/* <MDBInput className='mb-4' type='password' id='form3Example4' label='Password' /> */}

                <MDBBtn type="submit" className="mb-4" block>
                  Submit
                </MDBBtn>
              </form>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default NewLeads;
