import React, { useState } from "react";
import "./sidebar.css";
import { Routes, Route, Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import NewLeads from "../LeadsManagement/NewLeads";
import ViewLeads from "../LeadsManagement/ViewLeads";
import ag from "../../assets/images/favicon.ico";
// import bg from "../../assets/images/Xqg8.gif";

export const Sidebar = (props) => {
  const [activeLink, setActiveLink] = useState("");
  const navigate = useNavigate();
  let userInfo = JSON.parse(localStorage.getItem("user-info"));
  let user1 = userInfo.user_data.first_name;
  let user2 = userInfo.user_data.last_name;

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const toggleSidebar = () => {
    const sidebar = document.querySelector(".sidebar");
    const sidebarBtn = document.querySelector(".sidebarBtn");
    sidebar.classList.toggle("active");
    if (sidebar.classList.contains("active ")) {
      sidebarBtn.classList.replace("bx-menu", "bx-menu-alt-right");
    } else {
      sidebarBtn.classList.replace("bx-menu-alt-right", "bx-menu");
    }
  };

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
    toggleSidebar();
  };

  // Loader effect
  // const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   setIsLoading(true);
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 3000);
  // }, []);

  return (
    <>
      <div>
     
          {/* <div>
            <div className="loader-container">
              <img src={ag} alt="" style={{ width: "40px", height: "40px" }} />
              <div
                style={{
                  color: "white",
                  fontSize: "22px",
                  fontWeight: "bold",
                  textShadow:
                    "1px 1px 0 orange, 1px -1px 0 orange, -1px -1px 0 orange, -1px 1px 0 orange",
                }}
              >
                HUMHAI
              </div>
              <div className="loader"></div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Loading
                </div>
                <div>
                  <img
                    src={bg}
                    alt=""
                    style={{ width: "50px", height: "40px" }}
                  />
                </div>
              </div>
            </div>
          </div> */}
      
          <div>
            <div className="sidebar">
              <div className="logo-details" style={{ overflow: "hidden" }}>
                <i className="bx ">
                  <img src={ag} style={{ marginLeft: "25px" }} alt="" />
                </i>
                <span className="logo_name"> HumHai</span>
                <hr style={{ backgroundColor: "black", color: "black" }} />
              </div>
              <ul className="nav-links " style={{ overflow: "hidden" }}>
                <li>
                  <Link
                    to="/sidebar"
                    className={activeLink === "Dashboard" ? "active" : ""}
                    onClick={() => handleLinkClick("Dashboard")}
                  >
                    <i className="bx bx-grid-alt"></i>
                    <span className="links_name">Dashboard</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`dropdown ${
                      (activeLink === "leadsmanagment" ||
                        activeLink === "newleads" ||
                        activeLink === "viewleads") &&
                      "active"
                    }`}
                  >
                    <i className="bx bx-box"></i>
                    <span className="links_name">
                      Leads Management<i className="bx bx-chevron-down"></i>
                    </span>

                    <div
                      className="dropdown-content"
                      style={{
                        overflow: "hidden",
                        position: "absolute",
                        top: "70%",
                        left: "30%",
                      }}
                    >
                      <NavLink
                        to="/sidebar/newleads"
                        className={activeLink === "newleads" ? "active" : ""}
                        onClick={() => {
                          handleLinkClick("newleads");
                        }}
                      >
                        New Leads
                      </NavLink>

                      <NavLink
                        to="/sidebar/viewleads"
                        className={activeLink === "viewleads" ? "active" : ""}
                        onClick={() => handleLinkClick("viewleads")}
                      >
                        View Leads
                      </NavLink>
                    </div>
                  </Link>
                </li>

                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>

                <li className="log_out" style={{ overflow: "hidden" }}>
                  <a href="/" onClick={logout}>
                    <i className="bx bx-log-out"></i>
                    <span className="links_name">Log out</span>
                  </a>
                </li>
              </ul>
            </div>

            <section className="home-section">
              <div>
                <nav>
                  <div className="sidebar-button" onClick={toggleSidebar}>
                    <i className="bx bx-menu sidebarBtn"></i>
                    <span className="dashboard"></span>
                  </div>
                  <div className="search-box">
                    <input type="text" placeholder="Search..." />
                    <i className="bx bx-search"></i>
                  </div>
                  <div className="profile-details">
                    <img src={ag} alt="" />
                    <span className="admin_name">{user1 + " " + user2}</span>
                    {/* <i className="bx bx-chevron-down"></i> */}
                  </div>
                </nav>
              </div>

              <div>
                <Routes>
                  <Route path="/newleads" element={<NewLeads />} />
                  <Route path="/viewleads" element={<ViewLeads />} />
                </Routes>
              </div>
            </section>
          </div>

      </div>
    </>
  );
};
