import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../redux/authSlice";
// import gitUser from "../features/gitUserSlice";
// import {authSlice} from '../redux/authSlice'
export const Store = configureStore({
  reducer: {
    user:authSlice
  },
});

export default Store;