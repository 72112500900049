import React, { useState, useEffect } from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

function ViewLeads() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [user, setUser] = useState(null);

  function getUser() {
    let userInfo = JSON.parse(localStorage.getItem("user-info"));
    let user1 = userInfo.user_data.id;

    fetch(`https://humhai.in/Team-leader/Lead/${user1}`)
      .then((res) => res.json())
      .then((result) => {
        setUser(result);
      });
  }

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: 20,
            margin: 10,
            background: "#FFF",
            width: "90%",
            borderRadius: 10,
            overflowX: "scroll",
            marginTop: !matches ? "15%" : "50%",
          }}
        >
          <MDBTable>
            <MDBTableHead>
              <tr style={{ fontSize: "14px" }}>
                <th>Lead_id</th>
                <th>Organization_Name</th>
                <th>Contact_Person</th>
                <th>Location</th>
                <th>Mobile</th>
                <th>Email_id</th>
                <th>Service</th>
                <th>Service_Sub_Types</th>
                <th>Status</th>
                <th>Remarks</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {user?.map((userObj, index) => (
                <tr>
                  <td>{userObj.id}</td>
                  <td>{userObj.org_name}</td>
                  <td>{userObj.contact_person}</td>
                  <td>{userObj.location}</td>
                  <td>{userObj.mobile}</td>
                  <td>{userObj.email}</td>
                  <td>{userObj.service}</td>
                  {/* <td>{userObj.status}</td> */}
                  {/* <td>{userObj.Remark}</td>
                    <td>{userObj.Added_by}</td>
                    <td>{userObj.created}</td> */}
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>
        </div>
      </div>
    </div>
  );
}

export default ViewLeads;
