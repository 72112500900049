import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/login/Login";
import NewLeads from "./components/LeadsManagement/NewLeads";
import ViewLeads from "./components/LeadsManagement/ViewLeads";
import { Sidebar } from "./components/sidebar/Sidebar";
// import Sidebar from './components/sidebar/Sidebar'

function App() {
  return (
    <div>
      {/* <Login/> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/newleads" element={<NewLeads />} />
          <Route path="/viewleads" element={<ViewLeads />} />
          <Route path="/sidebar/*" element={<Sidebar />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

// import React, { useState } from "react";
// import {
//   Button,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   TextField,
//   Grid,
// } from "@mui/material";
// import { MuiOtpInput } from "mui-one-time-password-input";
// import "./assets/css/style.css";

// function App() {
//   // const [open, setOpen] = useState(false);
//   const [openDialog1, setOpenDialog1] = useState(false);
//   const [openDialog2, setOpenDialog2] = useState(false);
//   const [openDialog3, setOpenDialog3] = useState(false);
//   const [mobileNumber, setMobileNumber] = useState("");
//   const [otp, setOtp] = useState("");
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");

//   const handleOpenDialog1 = () => {
//     setOpenDialog1(true);
//   };

//   const handleChange = (newValue) => {
//     setOtp(newValue);
//   };

//   const handleCloseDialog1 = async () => {
//     // Make an API request to send OTP based on the provided mobile number
//     const response = await fetch("https://humhai.in/forget-pass-app/", {
//       method: "POST",
//       body: JSON.stringify({ mobile: mobileNumber, work: "send_otp" }),
//       headers: {
//         "Content-Type": "application/json;charset=UTF-8",
//         Accept: "application/json",
//       },
//     });
//     const result = await response.json();

//     if (result.status === 200) {
//       setOpenDialog1(false);
//       setOpenDialog2(true);
//     }
//   };

//   const handleCloseDialog2 = async () => {
//     // Make an API request to verify OTP
//     const response = await fetch("https://humhai.in/forget-pass-app/", {
//       method: "POST",
//       body: JSON.stringify({
//         mobile: mobileNumber,
//         work: "verify_otp",
//         otp: otp,
//       }),
//       headers: {
//         "Content-Type": "application/json;charset=UTF-8",
//         Accept: "application/json",
//       },
//     });
//     const result = await response.json();

//     if (result.status === 200) {
//       setOpenDialog2(false);
//       setOpenDialog3(true);
//     }
//   };

//   const handleCloseDialog3 = () => {
//     setOpenDialog3(false);
//   };

//   return (
//     <div>
//       <Button variant="contained" onClick={handleOpenDialog1}>
//         Open Dialog 1
//       </Button>

//       <Dialog open={openDialog1} onClose={handleCloseDialog1}>
//         <DialogTitle id="alert-dialog-title">Forget Password</DialogTitle>
//         <DialogContent>
//           <div>
//             <div class="fa fa-lock"></div>
//           </div>
//           <Grid container spacing={2}>
//             <Grid item xs={12} style={{ marginTop: "2%" }}>
//               <TextField
//                 required
//                 fullWidth
//                 label="Mobile Number"
//                 value={mobileNumber}
//                 onChange={(e) => setMobileNumber(e.target.value)}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <Button
//                 fullWidth
//                 onClick={handleCloseDialog1}
//                 style={{
//                   backgroundImage: "linear-gradient(to right, red , yellow)",
//                 }}
//               >
//                 Next
//               </Button>
//             </Grid>
//           </Grid>
//         </DialogContent>
//       </Dialog>

//       <Dialog open={openDialog2} onClose={handleCloseDialog2}>
//         <DialogTitle>OTP Verification</DialogTitle>

//         <DialogContent>
//           <Grid item xs={12} style={{ marginTop: "2%" }}>
//             <p style={{ fontSize: "12px" }}>Type your 6 digit security code</p>
//             <MuiOtpInput length={6} value={otp} onChange={handleChange} />
//           </Grid>
//         </DialogContent>
//         <Button
//           fullWidth
//           onClick={handleCloseDialog2}
//           style={{
//             backgroundImage: "linear-gradient(to right, red , yellow)",
//           }}
//         >
//           Next
//         </Button>
//         <div style={{ textAlign: "center", marginTop: "2%" }}>
//           <p style={{ fontSize: "12px" }}>
//             Don't get the code?{" "}
//             <span style={{ color: "blue", cursor: "pointer" }}> Resend</span>
//           </p>
//         </div>
//       </Dialog>

//       <Dialog open={openDialog3} onClose={handleCloseDialog3}>
//         <DialogTitle>
//           {" "}
//           <p style={{ fontSize: "20px", textAlign: "center" }}>
//             Reset Account Password
//           </p>
//         </DialogTitle>
//         <DialogContent>
//           <Grid container spacing={2}>
//             <Grid item xs={12} style={{ marginTop: "2%" }}>
//               <TextField
//                 required
//                 fullWidth
//                 label="Password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//               />
//             </Grid>
//             <Grid item xs={12} style={{ marginTop: "2%" }}>
//               <TextField
//                 required
//                 fullWidth
//                 label="Confirm Password"
//                 value={confirmPassword}
//                 onChange={(e) => setConfirmPassword(e.target.value)}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <Button
//                 fullWidth
//                 onClick={handleCloseDialog3}
//                 style={{
//                   backgroundImage: "linear-gradient(to right, red , yellow)",
//                 }}
//               >
//                 Reset Password
//               </Button>
//             </Grid>
//           </Grid>
//         </DialogContent>

//         <DialogActions></DialogActions>
//       </Dialog>
//     </div>
//   );
// }

// export default App;
