import React, { useState } from "react";
import { MDBInput } from "mdb-react-ui-kit";
import { MDBBtn } from "mdb-react-ui-kit";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useNavigate, Link } from "react-router-dom";
import HumHaiLoginHeaderLogo from "../../assets/images/hum-hai-header.png";
import { Circles } from "react-loading-icons";
import { Button } from "@mui/material";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";
// import { MuiOtpInput } from "mui-one-time-password-input";
// import { signInUser } from "../../redux/authSlice";
// import { useDispatch } from "react-redux";

const Login = () => {
  const getCookie = (name) => {
    var cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  };

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [work, setWork] = useState("");
  const [incorrect, setIncorrect] = useState(false);
  const [incorrect1, setIncorrect1] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const [centredModal, setCentredModal] = useState(false);
  const [centredModal1, setCentredModal1] = useState(false);
  const [centredModal2, setCentredModal2] = useState(false);

  const openModal = () => setCentredModal(!centredModal);
  const openModal2 = () => setCentredModal2(!centredModal2);
  const [otp, setOtp] = useState("");

  const handleClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // console.log(username, password, "username");
    var csrftoken = getCookie("csrftoken");
    let result = await fetch("https://humhai.in/login-app/", {
      method: "POST",
      body: JSON.stringify({ username: username, password: password }),
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        "X-CSRFToken": csrftoken,
      },
    })
      .then((res) => {
        // console.log(res, "response");
        return res;
      })
      .catch((error) => {
        // console.log(error, "error");
      });

    console.log("after response");
    result = await result.json();
    // console.log(result, "result");
    // console.log(result.status, "true");
    if (result.status === true) {
      localStorage.setItem("user-info", JSON.stringify(result));
      // console.log(userInfo)

      setSuccess(true);

      setTimeout(() => {
        navigate("/sidebar");
        setSuccess(false);
      }, 3000);

      setIsLoading(false);
    }
    if (result.status === 404) {
      setIncorrect1(true);
      setIncorrect(false);
      setIsLoading(false);
    }
    if (result.status === 401) {
      setIncorrect(true);
      setIncorrect1(false);
      setIsLoading(false);
    }

    // console.log(result.msg);
  };

  //   const dispatch = useDispatch()
  //  const handleClick = async (e) =>{
  //   e.preventDefault()
  //   console.log(username,password)
  //   dispatch(signInUser({username,password}))
  //  }

  const forgetPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(username, "username");
    var csrftoken = getCookie("csrftoken");
    let result = await fetch("https://humhai.in/forget-pass-app/", {
      method: "POST",
      body: JSON.stringify({ mobile: username, work: work, otp: otp }),
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        "X-CSRFToken": csrftoken,
      },
    })
      .then((res) => {
        // console.log(res, "response");
        return res;
      })
      .catch((error) => {
        // console.log(error, "error");
      });

    // console.log("after response");
    result = await result.json();
    // console.log(result, "result");
    // console.log(result.status, 200);
    if (result.msg === "otp is sent") {
      // console.log("hi");
    }
    if (result.msg === "verify_otp") {
      // console.log("hi2wwwf");
    }
    if (result.status === 200) {
      // setSuccess(true);
      setCentredModal(false);
      setCentredModal1(true);
      setIsLoading(false);
    }
    if (result.status === 404) {
      setIncorrect1(true);
      setIsLoading(false);
    }
    if (result.status === 401) {
      setIncorrect(true);
      setIsLoading(false);
    }

    console.log(result.msg);
  };

  return (
    <>
      <div className="full-screen-background">
        {success ? (
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="success">
              This is a success alert — check it out!
            </Alert>
          </Stack>
        ) : (
          ""
        )}
        {isLoading ? (
          <div
            md={6}
            style={{
              position: "absolute",
              top: "30%",
              right: "30%",
              zIndex: 1,
            }}
          >
            {" "}
            <Circles stroke="#0000FF" />
          </div>
        ) : (
          ""
        )}

        <div className="container-fluid" style={{ marginTop: "10%" }}>
          <div className="main-container row">
            <div className="col-md-6 text-center">
              <img
                className="img-fluid"
                src={HumHaiLoginHeaderLogo}
                alt="Hum Hai Logo Header"
              />
            </div>

            <div className="col-md-5 mt-5">
              <form onSubmit={handleClick}>
                <div className="container-fluid">
                  <div className="row">
                    {incorrect ? (
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert severity="error">
                          {" "}
                          Incorrect login credentials. Please check you username
                          and password and try again !!
                        </Alert>
                      </Stack>
                    ) : (
                      ""
                    )}
                    {incorrect1 ? (
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert severity="error"> User does not exist !!</Alert>
                      </Stack>
                    ) : (
                      ""
                    )}

                    <div className="col-12 my-2">
                      <MDBInput
                        label="Username"
                        id="formControlLg"
                        type="text"
                        size="lg"
                        maxLength={10}
                        required
                        onChange={(e) => {
                          setUsername(e.target.value);
                        }}
                        value={username}
                      />
                      <br />
                      <MDBInput
                        label="Password"
                        id="typePassword"
                        type="password"
                        size="lg"
                        // maxLength={10}
                        required
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        value={password}
                      />
                      <div className="d-grid gap-2 my-2">
                        <MDBBtn type="submit">Submit</MDBBtn>
                      </div>
                      <Link onClick={() => setCentredModal(true)}>
                        <p>
                          <span style={{ color: "blue" }}>
                            Forgot Password?
                          </span>
                          Click here to reset.
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Forgot Password Open Model*/}
      <MDBModal tabIndex="-1" show={centredModal} setShow={setCentredModal}>
        <MDBModalDialog centered>
          <MDBModalContent className="addleads">
            <MDBModalHeader>
              <MDBModalTitle>Forget Password</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form onSubmit={forgetPassword}>
                <MDBRow className="mb-4">
                  <MDBCol md={12}>
                    <MDBInput
                      id="form3Example1"
                      label="Enter Registered Mobile No."
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                      value={username}
                      required
                    />
                  </MDBCol>
                </MDBRow>
                <MDBBtn
                  type="next"
                  className="mb-2"
                  block
                  onClick={() => setWork("send_otp")}
                  style={{
                    backgroundImage: "linear-gradient(to right, red , yellow)",
                  }}
                >
                  Next
                </MDBBtn>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Button variant="outlined" color="error" onClick={openModal}>
                Cancel
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      {/* OTP Open Model */}
      <MDBModal tabIndex="-1" show={centredModal1} setShow={setCentredModal1}>
        <MDBModalDialog centered>
          <MDBModalContent className="addleads">
            <MDBModalHeader>
              <MDBModalTitle>OTP Verification</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form onSubmit={forgetPassword}>
                <p style={{ fontSize: "12px" }}>
                  Type your 6 digit security code
                </p>
                <MDBRow className="mb-3">
                  <MDBCol md={12}>
                    <MDBInput
                      id="form3Example1"
                      label="OTP"
                      onChange={(e) => {
                        setOtp(e.target.value);
                      }}
                      value={otp}
                      required
                    />
                  </MDBCol>
                </MDBRow>
                <MDBBtn
                  type="next"
                  className="mb-2"
                  block
                  onClick={() => {
                    setWork("verify_otp");
                    setUsername("");
                  }}
                  style={{
                    backgroundImage: "linear-gradient(to right, red , yellow)",
                  }}
                >
                  Next
                </MDBBtn>
              </form>

              <div style={{ textAlign: "center", marginTop: "2%" }}>
                <p style={{ fontSize: "12px" }}>
                  Don't get the code?{" "}
                  <span style={{ color: "blue", cursor: "pointer" }}>
                    {" "}
                    Resend
                  </span>
                </p>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <Button variant="outlined" color="error">
                Cancel
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      {/* Confirm Password Open Model*/}
      <MDBModal tabIndex="-1" show={centredModal2} setShow={setCentredModal2}>
        <MDBModalDialog centered>
          <MDBModalContent className="addleads">
            <MDBModalHeader>
              <MDBModalTitle>Confirm Password</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={openModal2}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <p style={{ fontSize: "20px", textAlign: "center" }}>
                  Reset Account Password
                </p>
                <MDBRow className="mb-4">
                  <MDBCol md={12}>
                    <MDBInput id="form3Example2" label="Password" required />
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mb-4">
                  <MDBCol md={12}>
                    <MDBInput
                      id="form3Example3"
                      label="Confirm Password*"
                      required
                    />
                  </MDBCol>
                </MDBRow>
              </form>
              <MDBBtn
                type="submit"
                className="mb-2"
                block
                style={{
                  backgroundImage: "linear-gradient(to right, red , yellow)",
                }}
              >
                Reset Password
              </MDBBtn>
            </MDBModalBody>
            <MDBModalFooter>
              <Button variant="outlined" color="error" onClick={openModal2}>
                Cancel
              </Button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default Login;
